.react-flow__node-selectorNode {
    font-size: 12px;
    background: red;
    border: 1px solid #555;
    border-radius: 5px;
    text-align: center;
}

.react-flow__node {
    z-index: -1 !important;
}